'use client';

import type { ListBannerDto } from '@/api/types';

import 'swiper/css/pagination';
import Link from 'next/link';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './MainCardSlider.module.scss';

interface MainCardSliderProps {
  bannerList?: ListBannerDto[];
}

export const MainCardSlider = ({ bannerList }: MainCardSliderProps) => (
  <section>
    <Swiper
      grabCursor
      loop
      autoplay={{
        delay: 5_000,
        disableOnInteraction: false,
      }}
      className={styles['swiper-wrapper']}
      modules={[Pagination, Autoplay]}
      pagination={{
        type: 'custom',
        horizontalClass: styles['custom-pagination-wrap'],
        renderCustom: (_, current, total) =>
          `<div class=${styles['custom-pagination']}>${current} / ${total}<div>`,
      }}
      spaceBetween={8}
    >
      {bannerList?.map(
        ({ file_url, link_url, target_new_yn, title }, index) => {
          if (!(link_url && file_url)) throw new Error('file_url is required');

          return (
            <SwiperSlide key={index} className={styles.card}>
              <Link
                className={styles['card-link']}
                href={link_url}
                target={target_new_yn ? '_blank' : '_self'}
              >
                <img alt={title} className={styles.thumbnail} src={file_url} />
              </Link>
            </SwiperSlide>
          );
        },
      )}
    </Swiper>
  </section>
);
