import type { QueryKey } from '@tanstack/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AUTH_API } from '@/api';

interface UseLikeMutationProps {
  queryKey: QueryKey;
}

interface LikeMutationParams {
  contentId: number;
  isLike: boolean;
  likeType: string;
}

export const useLikeMutation = ({ queryKey }: UseLikeMutationProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: ({ contentId, isLike, likeType }: LikeMutationParams) => {
      const requestBody = {
        requestBody: {
          content_id: contentId,
          like_type_cd: likeType,
        },
      };

      return isLike
        ? AUTH_API.deleteLike(requestBody)
        : AUTH_API.postLike(requestBody);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    postLike: mutate,
  };
};
