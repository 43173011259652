import type { RecommendPolicySearchDto } from '@generatedapi/auth';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';
import { useAuthStore } from '@/stores/AuthStore';

interface UseGetRecommendPolicyListQueryParams {
  size?: number;
  codeProvisionType?: string;
  welloMemberFamilyId?: number;
}

const INITIAL_PAGE = 1;

//TODO: 추후 useGetProvisionTypeListQuery 해당 hook과 묶어야함
export const useGetRecommendPolicyListQuery = ({
  size = 100,
  codeProvisionType,
  welloMemberFamilyId,
}: UseGetRecommendPolicyListQueryParams = {}) => {
  const isLogin = useAuthStore((state) => !!state.isLogin);

  return useInfiniteQuery({
    queryKey: [
      queryKeys.recommendPolicyList,
      codeProvisionType,
      welloMemberFamilyId,
      size,
    ],
    queryFn: ({ pageParam = INITIAL_PAGE }) => {
      const params = {
        page: pageParam,
        size,
        codeProvisionType,
        welloMemberFamilyId,
      };

      return AUTH_API.getRecommendPolicyList(params);
    },
    enabled: isLogin,
    select: (data) => {
      return {
        pages: data.pages.map(({ context }) => {
          const {
            new_policy_provision_type_list,
            new_policy_list,
            provision_type_list,
            policy_list,
            total_count,
            next_page_yn,
            policy_days_ago,
            new_policy_days_ago,
          } = context ?? {};

          return {
            policyDaysAgo: policy_days_ago,
            newPolicyDaysAgo: new_policy_days_ago,
            newPolicyProvisionTypeList: new_policy_provision_type_list
              ? [
                  { code: undefined, value: '전체' },
                  ...new_policy_provision_type_list,
                ]
              : [],
            newPolicyList: new_policy_list?.map(transformPolicy).slice(0, 3),
            provisionTypeList: provision_type_list
              ? [{ code: undefined, value: '전체' }, ...provision_type_list]
              : [],
            policyList: policy_list?.map(transformPolicy),
            totalCount: total_count,
            hasMorePage: next_page_yn ?? false,
          };
        }),
        pageParams: data.pageParams,
      };
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.context?.total_count && lastPage.context.page) {
        const nextPage = lastPage.context.page + 1;
        const maxPage = Math.ceil(lastPage.context.total_count / size);

        return nextPage <= maxPage ? nextPage : undefined;
      }
    },
    initialPageParam: INITIAL_PAGE,
    placeholderData: keepPreviousData,
  });
};

const transformPolicy = (metaPolicy: RecommendPolicySearchDto) => {
  return {
    id: metaPolicy.id,
    isThumbUp: metaPolicy.like_yn,
    idIdx: metaPolicy.meta_policy_id_idx,
    policyName: metaPolicy.policy_name,
    dday: metaPolicy.dday,
    agencyLogo: metaPolicy.agency_logo,
    agency: metaPolicy.agency,
    dueDateEnd: metaPolicy.expiration_date,
    supportBenefit: metaPolicy.support_benefit,
    provisionTypeListStr: metaPolicy.provision_type_str_list,
    likeCount: metaPolicy.like_count,
    commentCount: metaPolicy.comment_count,
    isWishlist: metaPolicy.wishlist_yn,
  };
};
