import { useInfiniteQuery as createInfiniteQuery } from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';
import { useAuthStore } from '@/stores/AuthStore';

interface HometownListProps {
  codeRegion: string;
  codeSubRegion: string;
  size?: number;
}

const INITIAL_PAGE = 1;

export const useGetHometownListQuery = ({
  codeRegion,
  codeSubRegion,
  size = 10,
}: HometownListProps) => {
  const isLogin = useAuthStore((state) => state.isLogin);

  try {
    return createInfiniteQuery({
      queryKey: [
        queryKeys.hometownNewsList,
        codeRegion,
        codeSubRegion,
        size,
        isLogin,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          codeRegion,
          codeSubRegion,
          page: pageParam,
          size,
        };

        return isLogin
          ? AUTH_API.getHometownList(params)
          : UNAUTH_API.getHometownList(params);
      },
      enabled: isLogin !== undefined,
      select: (data) => ({
        pages: data.pages.map(({ context }) => ({
          contents: context?.contents?.map((news) => {
            return {
              id: news.hometown_news_id,
              idIdx: news.hometown_news_id_idx,
              title: news.hometown_news_name,
              thumbnail: news.thumbnail,
              thumbUpCount: news.like_count,
              commentCount: news.comment_count,
              isThumbUp: news.like_yn,
              isBookmarked: news.wishlist_yn,
              dateText: news.published_at,
            };
          }),
          totalCount: context?.total_count,
        })),
        pageParams: data.pageParams,
      }),
      getNextPageParam: (lastPage) => {
        if (lastPage.context?.total_count && lastPage.context.page) {
          const nextPage = lastPage.context.page + 1;
          const maxPage = Math.ceil(lastPage.context.total_count / size);

          return nextPage <= maxPage ? nextPage : undefined;
        }
      },
      initialPageParam: INITIAL_PAGE,
    });
  } catch {
    setTimeout(() => {
      location.reload();
    }, 500);

    return {
      fetchNextPage: () => {},
    };
  }
};
